exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-acknowledgements-js": () => import("./../../../src/templates/acknowledgements.js" /* webpackChunkName: "component---src-templates-acknowledgements-js" */),
  "component---src-templates-buy-with-bookby-js": () => import("./../../../src/templates/buy-with-bookby.js" /* webpackChunkName: "component---src-templates-buy-with-bookby-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-open-on-mobile-js": () => import("./../../../src/templates/open-on-mobile.js" /* webpackChunkName: "component---src-templates-open-on-mobile-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-safepay-js": () => import("./../../../src/templates/safepay.js" /* webpackChunkName: "component---src-templates-safepay-js" */),
  "component---src-templates-terms-of-use-js": () => import("./../../../src/templates/terms-of-use.js" /* webpackChunkName: "component---src-templates-terms-of-use-js" */)
}

